import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import DateDropdown from 'components/DateDropdown';
import EmpoweredSelector from 'components/EmpoweredSelector';
import { default as StationsService } from 'services/Stations';
import { default as ClockingsService } from 'services/Clockings';
import moment from 'moment';
import { cristalLoader } from 'helpers/generic';
import { toast } from 'react-toastify';

const Styled = styled.div`
    padding: 15px;
    position: relative;

    background: var(--bs-gray-200);

    textarea {
        resize: none;
    }

    label {
        display: block;
        color: var(--plenoil-gray);
        font-size: 10px;
    }

    #new-destination-title {
        cursor: default;
        max-width: 150px;
    }

    #dates-wrapper {

        [class*="date-dropdown"] {
            width: 100%;

            & > .dropdown {
                width: 100%;

                & > .btn {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    background: white;
                    white-space: nowrap;
                    font-size: 13px;
                    width: 100%;
                    text-align: left;

                    small {
                        align-self: flex-end;
                    }
                }
            }
        }

        .date-row {
            margin-bottom: 10px;

            .btn-delete {
                background: none;
                color: var(--bs-danger);
                border: 0;
                position: absolute;
                right: -10px;
                top: 80%;
                transform: translateY(-80%);

                &:hover {
                    transform: translateY(-80%) scale(1.2);
                }
            }
        }
    }
`;

export default function NewDestination(props) {
    const onCancel = props.onCancel;
    const dispenser = props.dispenser;

    const [loading, setLoading] = useState(false);
    const [station, setStation] = useState(null);
    const [reason, setReason] = useState(null);
    const [dates, setDates] = useState([]);
    const [notes, setNotes] = useState(null);
    const [errors, setErrors] = useState(null);
    
    const setDatesRange = (idx, range) => {
        setDates((prev) => {
            let newDates = [...prev];
            newDates[idx].from = range.from;
            newDates[idx].to = range.to;
            return newDates;
        });
	}

    const setDateRowField = (index, field, value) => {
        if (field === 'range') {
            setDatesRange(index, value);
        } else {
            setDates((prev) => {
                let newDates = [...prev];
                newDates[index][field] = value;
                return newDates;
            });
        }
    }

    const setDateRowIntervalField = (index, interval, field, value) => {
        setDates((prev) => {
            let newDates = [...prev];
            newDates[index].intervals[interval][field] = value;
            return newDates;
        });
    }

    const loadStations = async (input, callback) => {
        let stations = await StationsService.getList({
            search: input,
            no_paginate: true
        });
        
        callback(stations?.map(el => {
            return {
                value: el,
                label: el.name
            }
        }));
    }

    const addDate = useCallback(() => {
        let newDate = {
            from: null,
            to: null,
            intervals: [
                {
                    start_at: null,
                    end_at: null
                },
                {
                    start_at: null,
                    end_at: null
                }
            ]
        };

        setDates((prev) => [...prev, newDate]);
    }, []);

    const deleteDate = (index) => {
        setDates((prev) => {
            let newDates = [...prev];
            if ( newDates.length > 1 ) newDates.splice(index, 1);
            return newDates;
        });
    }

    useEffect(() => {
        addDate();
    }, [addDate]);

    const saveData = async () => {
        setLoading(true);
        let result = await ClockingsService.addDispenserDestination(dispenser.id, station?.id, reason, dates, notes);
        console.log(result);
        if ( result?.status === 1 ) {
            toast.success('Destino añadido correctamente');
            onCancel();
        } else if ( result.errors ) {
            setErrors(result.errors);
        }
        setLoading(false);
    }
    
    return (
        <Styled>
            {loading && cristalLoader}
            <span className="btn btn-sm btn-plenoil-primary py-0 w-50" id="new-destination-title"><i className="bi bi-person"></i> NUEVO DESTINO</span>

            <button className="btn btn-sm btn-plenoil-primary py-0 ms-4" onClick={saveData}>CONFIRMAR</button>
                    
            <button className="btn btn-sm btn-danger py-0 ms-2" onClick={onCancel}>CANCELAR</button>

            <div className="row w-100 mt-4">
                <div className="col-md-2">
                    <div className="">
                        <label>MOTIVO</label>
                        <input type="text" className="form-control form-control-sm" value={reason ?? ''} onChange={(e) => setReason(e.target.value)} />
                        {(errors?.reason && errors?.reason[0]) && <small className="invalid-feedback d-block">{errors.reason[0]}</small>}
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="">
                        <label>ESTACIÓN</label>
                        <EmpoweredSelector
                            load={(input, callback) => loadStations(input, callback)}
                            onChange={(value, text) => setStation(value)}
                            timeout={200}
                            label={station?.name}
                            value={station?.id ?? null}
                            placeholder="Estación"
                        />
                    </div>
                    {(errors?.station_id && errors?.station_id[0]) && <small className="invalid-feedback d-block">{errors.station_id[0]}</small>}
                </div>
                <div className="col-md-6" id="dates-wrapper">
                    {dates.map((dateRow, index) => {
                        return (
                            <div className="row date-row" key={index}>
                                <div className="col-md-4">
                                    <label>FECHA</label>
                                    <DateDropdown 
                                        id={"date-dropdown-" + index}
                                        defaultPeriod={'custom'} 
                                        onChange={(period, range) => setDateRowField(index, 'range', range)}
                                        onlyDates={true}
                                        showPeriods={false}
                                        startRange={{from: dateRow.from, to: dateRow.to}}
                                    />
                                    {(errors && errors['dates.' + index + '.from'] && errors['dates.' + index + '.from'][0]) && <small className="invalid-feedback d-block">{errors['dates.' + index + '.from'][0]}</small>}
                                </div>
                                <div className="col-md-8">
                                    <div className="row">
                                        <div className="col-md-3 pe-0">
                                            <div className="">
                                                <label>HORA ENTRADA</label>
                                                <input type="time" className="form-control form-control-sm" value={dateRow.intervals[0].start_at ?? ''} onChange={(e) => setDateRowIntervalField(index, 0, 'start_at', e.target.value)} />
                                                {(errors && errors['dates.' + index + '.intervals.0.start_at'] && errors['dates.' + index + '.intervals.0.start_at'][0]) && <small className="invalid-feedback d-block">{errors['dates.' + index + '.intervals.0.start_at'][0]}</small>}
                                            </div>
                                        </div>
                                        <div className="col-md-3 ps-0">
                                            <div className="">
                                                <label>HORA SALIDA</label>
                                                <input type="time" className="form-control form-control-sm" value={dateRow.intervals[0].end_at ?? ''} onChange={(e) => setDateRowIntervalField(index, 0, 'end_at', e.target.value)} />
                                                {(errors && errors['dates.' + index + '.intervals.0.end_at'] && errors['dates.' + index + '.intervals.0.end_at'][0]) && <small className="invalid-feedback d-block">{errors['dates.' + index + '.intervals.0.end_at'][0]}</small>}
                                            </div>
                                        </div>
                                        <div className="col-md-3 pe-0">
                                            <div className="">
                                                <label>HORA ENTRADA</label>
                                                <input type="time" className="form-control form-control-sm" value={dateRow.intervals[1].start_at ?? ''} onChange={(e) => setDateRowIntervalField(index, 1, 'start_at', e.target.value)} />
                                                {(errors && errors['dates.' + index + '.intervals.1.start_at'] && errors['dates.' + index + '.intervals.1.start_at'][0]) && <small className="invalid-feedback d-block">{errors['dates.' + index + '.intervals.1.start_at'][0]}</small>}
                                            </div>
                                        </div>
                                        <div className="col-md-3 ps-0 position-relative">
                                            <div className="">
                                                <label>HORA SALIDA</label>
                                                <input type="time" className="form-control form-control-sm" value={dateRow.intervals[1].end_at ?? ''} onChange={(e) => setDateRowIntervalField(index, 1, 'end_at', e.target.value)} />
                                                {(errors && errors['dates.' + index + '.intervals.1.end_at'] && errors['dates.' + index + '.intervals.1.end_at'][0]) && <small className="invalid-feedback d-block">{errors['dates.' + index + '.intervals.1.end_at'][0]}</small>}
                                            </div>

                                            <button onClick={() => deleteDate(index)} className="btn-delete"><i className="bi bi-x"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}

                    <button onClick={() => addDate()} className="btn btn-secondary btn-sm py-0">Añadir otra fecha</button>
                </div>
                <div className="col-md-2">
                    <div className="">
                        <label>NOTAS</label> 
                        <textarea type="text" className="form-control form-control-sm" rows="5" value={notes ?? ''} onChange={(e) => setNotes(e.target.value)}></textarea>
                    </div>
                </div>
            </div>
        </Styled>
    );
}