import React, { useEffect, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { default as SuppliesService } from 'services/Supplies';

const SubTable = styled.table`
	width: 100%;
	
	td, th {
		border: 1px solid gray;
		text-align: center;
		padding: 8px 10px;
	}

	thead {

		tr {

			th {
				color: var(--plenoil-brown-light);
				padding: 2px 10px;
			}
		}
	}

	tbody {

		tr {

			td {

				&:first-of-type {
					text-align: left;
				}

				&:nth-child(4),
				&:nth-child(5) {
					width: 100px;
				}
			}
		}
	}

	thead, tbody {
		td, th {
			
			&:first-of-type {
				border-left: 0;
			}
		}

		tr {

			&:first-of-type {
				th {
					border-top: 0;
				}
			}

			&:last-of-type {
				td {
					border-bottom: 0;
				}
			}
		}
	}

	tfoot {

		td {
			font-size: 12px !important;

			&:first-of-type {
				border-left: 0;
				border-bottom: 0;
			}

			&:last-of-type {
				border-bottom: 0;
			}
		}
	}
	
`;

export default function Details(props) {
	let id = props.id;
	let [order, setOrder] = useState({});

	useEffect(() => {
		let getData = async () => {
			let data = await SuppliesService.getBCOrderTc(id);
			setOrder(data);
		}
		getData();

		return function cleanup() {
			SuppliesService.cancel();
		}
	}, [id]);

	return (
		<tr className="details">
			<td colSpan="100%" className="p-0">
				<div className="row">
					<div className="col-md-6">
						<SubTable>
							<thead>
								<tr>
									<th></th>
									<th>CÓDIGO</th>
									<th>UNIDADES</th>
									<th colSpan="2">COMPROBACIÓN</th>
								</tr>
							</thead>
							<tbody>
								{order?.products?.map((el, idx) => {
									let icon = null;
									if ( el.checked_qty === null ) {
										icon = <i className="bi bi-ban text-warning"></i>;
									}
									if ( el.checked_qty !== null && el.checked_qty !== el.qty ) {
										icon = <i className="bi bi-x-circle-fill text-danger"></i>;
									}
									if ( el.checked_qty !== null && el.checked_qty === el.qty ) {
										icon = <i class="bi bi-check-circle-fill text-secondary"></i>;
									}

									return (
										<tr key={idx}>
											<td>
												{idx+1}. {el.name}
											</td>
											<td>
												{el.code}
											</td>
											<td>
												{el.qty}
											</td>
											<td>
												{el.checked_qty}
											</td>
											<td>
												{icon}
											</td>
										</tr>
									);
								})}
							</tbody>
							<tfoot>
								<tr>
									<td colSpan="3"></td>
									<td colSpan="2">
										{order.last_checked_at &&
											<>
												ACTUALIZADO: { moment(order.last_checked_at).format('DD-MM-YYYY / HH:mm') }
											</>
										}
									</td>
								</tr>
							</tfoot>
						</SubTable>
					</div>
					<div className="col-md-6">
						{order.sent_log &&
							<>
								<div className="mt-2">Respuesta de envío a BC: {moment(order.sent_at).format('DD-MM-YYYY / HH:mm')}</div>
								<div><small><span>gfu_GetPedido</span>: {order.sent_log['gfu_GetPedido']?.message ?? '-'}</small></div>
								<div><small><span>gfu_RegistrarPedido</span>: {order.sent_log['gfu_RegistrarPedido']?.message ?? '-'}</small></div>
							</>
						}
					</div>
				</div>
			</td>
		</tr>
	);
}


