import React, { useEffect, useState } from 'react';
import { NavLink, useSearchParams } from "react-router-dom";
import LoggedLayout from '../../layouts/LoggedLayout';
import moment from 'moment';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import PageHeader from 'components/PageHeader';
import Paginator from 'components/Paginator';
import ThSortable from 'components/ThSortable';
import TrSkeleton from 'components/TrSkeleton';
import DateDropdown from 'components/DateDropdown';
import { hasCapability } from 'helpers/user';
import EmpoweredSelector from 'components/EmpoweredSelector';
import CheckButton from './CheckButton';
import GlobalConfigModal from './GlobalConfigModal';
import { default as PricesService } from 'services/Prices';
import { default as StationsService } from 'services/Stations';

const Table = styled.table`
	th,
	td {
		vertical-align: middle;
		position: relative;

		&:nth-child(1) {
			width: 40px;
			text-align: center;

			input {
				pointer-events: none;
			}
		}

		&:nth-child(2) {

			.notification_status {

			}

			.station_code {
				width: 22px;
				height: 22px;
				font-size: 10px;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-left: 10px;
			}
		}

		&:nth-child(3) {
			width: 100px;
		}

		&:nth-child(4),
		&:nth-child(5),
		&:nth-child(6),
		&:nth-child(7) {
			width: 50px;
			text-align: right;
		}

		&:nth-child(8), 
		&:nth-child(9), 
		&:nth-child(10), 
		&:nth-child(11) { 
			width: 100px;
			text-align: center;
		}

		&:nth-child(12) {
			width: 80px;
			text-align: center;

			&.chat {
				i {
					color: var(--plenoil-green);

				}

				&.disabled {
					opacity: 0.8;
				}
			}
		}
	}
`;

const Filters = styled.div`
	display: flex;
	justify-content: end;

	@media (max-width: 1200px) {
		justify-content: center;
		flex-direction: column;

		& > *,
		.empowered-selector {
			width: 100% !important;
			display: block !important;
		}

		& > * {
			margin-bottom: 10px;
		}
	}
`;

const DateDropDownWrapper = styled.div` 
	padding: 0;
	min-height: 0;
	width: 192px;
	display: inline-block;

	& > .date-dropdown {
		display: block;

		& > .dropdown {
			width: 100%;
			display: block !important;

			& > .btn {
				padding: 0;
				width: 100% !important;
				display: flex;
				align-items: center;
				padding: 0 5px;

				small {
					margin-left: auto;
				}
			}
		}
	}
`;

const EmpoweredSelectorWrapper = styled.div` 
	margin-right: 10px;

	.empowered-selector {
		width: 200px;
		
		.empowered-selector_label {
			height: 28px;
			white-space: nowrap;
			overflow: hidden;

			&:hover {
				background: white;
			}

			.remove-icon {
				background: white;
				border-radius: 50%;
			}
		}
	}
`;

export default function PricesCheck() {
	const [queryParams] = useSearchParams();

	let [forceReload, setForceReload] = useState(null);
	let [alerts, setAlerts] = useState(null);
	let [checks, setChecks] = useState([]);
	let [selectedChecks, setSelectedChecks] = useState([]);
	let [sortDirection, setSortDirection] = useState('desc');
	let [sortField, setSortField] = useState('date');
	let [station, setStation] = useState(null);
	let [dateFrom, setDateFrom] = useState(moment().format('YYYY-MM-DD'));
	let [dateTo, setDateTo] = useState(moment().format('YYYY-MM-DD'));
	let [skeletonRows, setSkeletonRows] = useState(5);
	let [page, _setPage] = useState(queryParams.get('page') ?? undefined);
	let [globalConfigModalOpened, setGlobalConfigModalOpened] = useState(null);
	const setPage = (page) => {
		setChecks({...checks, data: undefined});
		_setPage(page);
	}

	const sortTableClick = (field) => {
		if ( !field ) return;
		if ( field === sortField ) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortField(field);
	};

	const setDatesRange = (range) => {
		setDateFrom(range.from);
		setDateTo(range.to);
	}

	const loadStations = async (input, callback) => {
		let stations = await StationsService.getList({
			search: input,
			no_paginate: true
		});
		
		callback(stations?.map(el => {
			return {
				value: el,
				label: el.name
			}
		}));
	}

	const openChat = (e, station_id) => {
		e.stopPropagation();

		const event = new CustomEvent('open-chat', {detail: {stationId: station_id, channel: 'tankertrucks'}});
		document.dispatchEvent(event);
	}

	const toggleSelectedCheck = (checkId) => {
		let newChecks = [...selectedChecks];

		let checked = newChecks.indexOf(checkId);
		if ( checked !== -1 ) {
			newChecks.splice(checked, 1);
		} else {
			newChecks.push(checkId);
		}

		setSelectedChecks(newChecks);
	}

	const deleteSelected = async () => {
		let c = window.confirm('¿Seguro que quieres eliminar estas comprobaciones de precios?');
		if ( !c ) return;

		let result = await PricesService.deleteChecksBulk(selectedChecks);
		if ( !result.status ) {
			toast.error('Error al cambiar el estado');
            return;
		}

		setSelectedChecks([]);
		setForceReload(Math.random());
	}

	useEffect(() => {

		return function cleanup() {
			PricesService.cancel();
			StationsService.cancel();
        }
	}, []);

	useEffect(() => {
		const getChecks = async () => {
			let checks = await PricesService.getChecksList({
				page: page,
				sort: sortField,
				direction: sortDirection,
				date_from: dateFrom,
				date_to: dateTo,
				station_id: station?.id
			});

			setChecks({...checks});
			setAlerts(checks?.data?.filter(el => el.totem_check === 0 || el.preciario_check === 0 || el.surtidor_check === 0 || el.autofuel_check === 0).length);
			setSkeletonRows(checks?.data?.length);
		};
		getChecks();

		let reloadInterval = setInterval(() => {
			getChecks();
		}, 5000);

		return function cleanup() {
			clearInterval(reloadInterval);
		}
	}, [forceReload, page, sortField, sortDirection, dateFrom, dateTo, station]);

	return (
		<LoggedLayout>
			<div className="col-md-12 mb-3">
				<PageHeader>
					<span className="header-column header-title">Control de precios</span>
					<span className="header-column" onClick={() => null} role="button">
                        <div className="header-column-text">
							Alertas
						</div>
						<div className="header-column-number position-relative" style={{color: 'white'}}>
							<i className={'bi bi-triangle-fill ' + (alerts ? 'text-danger' : 'text-secondary')}></i>
							<u 
								style={{
									textDecoration: 'none', 
									position: 'absolute', 
									top: '12px', 
									left: '3px', 
									fontSize: '15px', 
									width: '25px',
									textAlign: 'center'
								}}
							>
								{!alerts ? 0 : alerts}
							</u>
						</div>
					</span>
					
					<button className="btn btn-secondary btn-sm p-0 px-1 ms-1 mb-1 mt-1 float-end" onClick={() => setGlobalConfigModalOpened(true)}><i className="bi bi-exclamation-triangle"></i> Avisos</button>

					<span className="header-column header-icon-end">
                        <i className="bi bi-droplet-half text-plenoil-red"></i>
					</span>
				</PageHeader>
			</div>

			<div className="col-md-12 mb-3">
				<div className="bg-plenoil-red text-white p-2">
					<div className="row">
						<div className="col-md-2">
							Listado de comprobaciones
						</div>
						<Filters className="col-md-10">
							{ (selectedChecks.length > 0 && hasCapability('prices', 'delete'))  &&
								<button className="btn btn-sm btn-plenoil-blue-light py-0 px-2 me-2" onClick={() => deleteSelected()}>Eliminar</button>
							}

							<EmpoweredSelectorWrapper>
								<EmpoweredSelector
									load={(input, callback) => loadStations(input, callback)}
									onChange={(value, text) => setStation(value)}
									timeout={200}
									label={station?.name}
									value={station?.id ?? null}
									placeholder="Estación"
								/>
							</EmpoweredSelectorWrapper>

							<DateDropDownWrapper className="form-control form-control-sm me-2">
								<DateDropdown 
									defaultPeriod={'today'} 
									onChange={(period, range) => setDatesRange(range)}
									onlyDates={true}
								/>
							</DateDropDownWrapper>

							{ hasCapability('prices', 'add') &&
								<React.Fragment>
									<NavLink to="/prices-check/add" className="btn btn-sm btn-light p-0 px-2 d-inline-flex align-items-center">COMPROBACIÓN MANUAL</NavLink>
								</React.Fragment>
							}
						</Filters>
					</div>
				</div>
			</div>

			<div className="col-md-12 mb-3">
				<div className="table-responsive shadow-sm rounded">
					<Table className="table table-sm table-bordered table-striped table-custom bg-white mb-0">
						<thead>
							<tr>
								<th>Estado</th>
								<ThSortable direction={sortDirection} active={sortField === 'station.name'} onClick={() => sortTableClick('station.name')}>Estación</ThSortable>
								<ThSortable direction={sortDirection} active={sortField === 'date'} onClick={() => sortTableClick('date')}>Fecha</ThSortable>
								<ThSortable direction={sortDirection} active={sortField === 'goa_price'} onClick={() => sortTableClick('goa_price')}>GOA</ThSortable>
								<ThSortable direction={sortDirection} active={sortField === 'sp95_price'} onClick={() => sortTableClick('sp95_price')}>SP95</ThSortable>
								<ThSortable direction={sortDirection} active={sortField === 'goa_aditivado_price'} onClick={() => sortTableClick('goa_aditivado_price')}>GOA <small className="badge bg-light text-dark">ADITIVADO</small></ThSortable>
								<ThSortable direction={sortDirection} active={sortField === 'sp95_aditivado_price'} onClick={() => sortTableClick('sp95_aditivado_price')}>SP95 <small className="badge bg-light text-dark">ADITIVADO</small></ThSortable>
								<ThSortable direction={sortDirection} active={sortField === 'totem_check'} onClick={() => sortTableClick('totem_check')}>Totem</ThSortable>
								<ThSortable direction={sortDirection} active={sortField === 'preciario_check'} onClick={() => sortTableClick('preciario_check')}>Preciario</ThSortable>
								<ThSortable direction={sortDirection} active={sortField === 'surtidor_check'} onClick={() => sortTableClick('surtidor_check')}>Surtidor</ThSortable>
								<ThSortable direction={sortDirection} active={sortField === 'autofuel_check'} onClick={() => sortTableClick('autofuel_check')}>APP</ThSortable>
								<th>Chat</th>
							</tr>
						</thead>
						<tbody>
							{ checks.data?.map((el, idx) => {
								return (
									<tr key={el.id}>
                                        <td onClick={() => toggleSelectedCheck(el.id)}>
											<input type="checkbox" checked={selectedChecks.indexOf(el.id) !== -1} onChange={() => null} />
											{(el.totem_check === 0 || el.preciario_check === 0 || el.surtidor_check === 0 || el.autofuel_check === 0) &&
												<div><i className="bi bi-exclamation-triangle text-danger"></i></div>
											}
										</td>
                                        <td>{el.station?.name}</td>
                                        <td>{moment(el.date).format('DD-MM-YYYY HH:mm')}</td>
                                        <td>{parseFloat(el.goa_price) !== 0 ? parseFloat(el.goa_price).toFixed(3) : '-'}</td>
                                        <td>{parseFloat(el.sp95_price) !== 0 ? parseFloat(el.sp95_price).toFixed(3) : '-'}</td>
                                        <td>{el.goa_aditivado_price && parseFloat(el.goa_aditivado_price) !== 0 ? parseFloat(el.goa_aditivado_price).toFixed(3) : '-'}</td>
                                        <td>{el.sp95_aditivado_price && parseFloat(el.sp95_aditivado_price) !== 0 ? parseFloat(el.sp95_aditivado_price).toFixed(3) : '-'}</td>
                                        <td>
											<CheckButton 
												checkId={el.id}
												status={el.totem_check}
												type={'totem'}
												forceReload={() => setForceReload(Math.random())} 
											/>
										</td>
                                        <td>
											<CheckButton 
												checkId={el.id}
												status={el.preciario_check}
												type={'preciario'}
												forceReload={() => setForceReload(Math.random())} 
											/>
										</td><td>
											<CheckButton 
												checkId={el.id}
												status={el.surtidor_check}
												type={'surtidor'}
												forceReload={() => setForceReload(Math.random())} 
											/>
										</td><td>
											<CheckButton 
												checkId={el.id}
												status={el.autofuel_check}
												type={'autofuel'}
												forceReload={() => setForceReload(Math.random())} 
											/>
										</td>
                                        <td className={'chat ' + (!el.pending_chat_messages_count ? 'disabled' : '')}>
                                            <button className="btn btn-link btn-sm" onClick={(e) => openChat(e, el.station_id)}><i className={'bi bi-chat-right' + (el.pending_chat_messages_count ? '-fill' : '')}></i></button>
                                        </td>
                                    </tr>
								)
							}) }

							{ checks.data && !checks.data.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

							{ checks.data === undefined && <TrSkeleton rows={skeletonRows} columns={12} /> }
						</tbody>
					</Table>
				</div>
			</div>

			<div className="col-md-12 text-end mb-3">
				<div className="d-inline-block">
					<Paginator
						min={1}
						current={checks?.current_page}
						max={checks?.last_page}
						changeCallback={(page) => setPage(page)}
					/>
				</div>
			</div>

			{globalConfigModalOpened &&
				<GlobalConfigModal 
					closeCallback={() => {
						setGlobalConfigModalOpened(null);
					}}
				/>
			}
		</LoggedLayout>
	);
}